<template>
  <section class="shop-by-brand">
    <!-- <div class="container">
      <div class="row">
        <div class="col heading-title text-center">
          <h2 class="title">Featured Brand</h2>
        </div>
      </div>
    </div>
    <PuSkeleton v-if="isLoading" height="300px" />
    <VueSlickCarousel
      v-bind="slickSettings"
      class="slider-product-box px-lg-5"
      v-else-if="brands.length"
    >
      <BrandShowcase v-for="(brand, i) in brands" :key="i" :brand="brand" /> -->
      <!-- <div v-for="i in 5" :key="i">{{i}}</div> -->
      <!-- <router-link v-for="(brand, index) in brands" :key="index"
        :to="'/shop-by-brands/' + brand.slug + '-official-store-' + brand.id">
        <b-img-lazy :src="brand.image_url" />
      </router-link> -->
    <!-- </VueSlickCarousel> -->

    <!-- <div class="pt-3 pt-lg-4 text-center">
      <router-link :to="'/shop-by-brands'" class="btn btn-wz-outline-gold mt-0">
        VIEW MORE
      </router-link>
    </div> -->
    <b-skeleton-wrapper :loading="isLoading">
      <template #loading>
        <div class="container">
          <div class="row">
            <div class="col heading-title text-center">
              <b-skeleton class="mx-auto mb-3" height="42px" width="200px"></b-skeleton>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="homepage-skeleton-featured-brand">
              <b-skeleton-img aspect="16:9" class="featured-brand-box-skeleton" v-for="i in 4" :key="`featured-brand-skeleton-${i}`"></b-skeleton-img>
            </div>
            <b-skeleton class="mx-auto mt-3 mt-lg-4" type="button" width="137px" height="46px"></b-skeleton>
          </div>
        </div>
      </template>
      <template #default>
        <div class="container">
          <div class="row">
            <div class="col heading-title text-center">
              <h2 class="title">Featured Brand</h2>
            </div>
          </div>
        </div>
        <PuSkeleton v-if="isLoading" height="300px" />
        <VueSlickCarousel
          v-bind="slickSettings"
          class="slider-product-box px-lg-5"
          v-else-if="brands.length"
        >
          <BrandShowcase v-for="(brand, i) in brands" :key="i" :brand="brand" />
        </VueSlickCarousel>

        <div class="pt-3 pt-lg-4 text-center">
          <router-link :to="'/shop-by-brands'" class="btn btn-wz-outline-gold mt-0">
            VIEW MORE
          </router-link>
        </div>
      </template>
    </b-skeleton-wrapper>
    <div class="container pt-4">
      <hr />
    </div>
  </section>
</template>

<script>
import { toaster } from '@/_helpers';
import BrandShowcase from './BrandShowcase.vue';
import VueSlickCarousel from "vue-slick-carousel";

export default {
  name: "ShopBrand",
  components: {
    BrandShowcase,
    VueSlickCarousel,
  },
  data() {
    return {
      brands: [],
      slickSettings: {
        arrows: true,
        // centerMode: true,
        // centerPadding: "0px",
        // slidesToShow: 4,
        slidesToShow: 4,
        slidesToScroll: 4,
        swipeToSlide: true,
        responsive: [
          {
            breakpoint: 769,
            settings: {
              arrows: false,
              // centerPadding: "0px",
              // slidesToShow: 2,
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 481,
            settings: {
              arrows: false,
              // centerPadding: "30px",
              // slidesToShow: 1,
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      isLoading: true,
    };
  },
  mounted() {
    this.getAllBrands()
  },
  methods: {
    async getAllBrands() {
      try {
        const res = await this.$api.cms.getBrandsV2()
        if (res.status === 200) this.brands = res.data.data
        else toaster.make(res.data.message, 'danger')
      } catch (e) {
        if (e.response) toaster.make(e.response, 'danger')
        console.error(e);
      }
      this.isLoading = false
    }
  }
};
</script>
<style scoped>
*:deep(.featured-brand-box-skeleton) {
  border-radius: 1rem;
}
</style>